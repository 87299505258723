import React from 'react';
import {getUrl} from '../utils/utils.js'

class KontrollerPrUgeDag extends React.Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [] };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        return <div>
            <div className="h6 text-center">
                Antal kontroller pr. ugedag
            </div>
            <div>
                <table id="dtBasicExample" className="table table-striped table-bordered table-sm" cellSpacing="0" width="100%">
                    <thead>
                        <tr>
                            <th className="th-sm">Ugedag</th>
                            <th className="th-sm">Antal kontroller</th>
                            <th className="th-sm">Relativ fordeling</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.componentdata.map(c =>
                            <tr key={c.str}>
                                <td>{c.str}</td>
                                <td>{c.val}</td>
                                <td className="text-right">{c.relVal.toFixed(2)} %</td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
            <div>
                Opsummering: Kontroller foregår primært i hverdagene
            </div>
        </div>;
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Kontrol/kontrollerprugedag'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}

export default KontrollerPrUgeDag;