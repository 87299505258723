import React from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import {getUrl} from '../utils/utils.js'

class VirksomhedstypeComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [] };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        let chart = {
            labels: this.state.componentdata.map(function (c) {
                return c["virksomhedsType"] + " (antal: " + c["antal"] + ")";
            }),
            datasets: [
                {
                    label: "Reklamebeskyttelse Fordeling",
                    backgroundColor: ["#6cceff", "#ff9d6c"],
                    data: this.state.componentdata.map(function (c) {
                        return c["antal"];
                    })
                }
            ]
        };

        let chartoptions = {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: 'Fordeling af virksomhedstyper'
            }
        }

        return <div>
            <div className="h3 text-center">
                Fordelingen af virksomheder pr. type af virksomhed
            </div>
            <div style={{height:'40vh'}}>
                <Chart type='doughnut' data={chart} options={chartoptions}/>
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
        </div>;
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Virksomhed/virksomhedstypefordeling'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}

export default VirksomhedstypeComponent;