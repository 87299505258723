import React from 'react';
//import { Chart } from 'react-chartjs-2';
import { getUrl } from '../utils/utils.js'

class KaedefordelingComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [] };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        return "";
        /*let ddata = this.state.componentdata.map(function (c) {
            return c["smileys"];
        });

        var data1 = [];
        var data2 = [];
        var data3 = [];
        var data4 = [];

        for (var i = 0; i < ddata.length; i++) {
            for (var j = 0; j < ddata[i].length; j++) {
                var sum = ddata[i][0]["antal"] + ddata[i][1]["antal"] + ddata[i][2]["antal"] + ddata[i][3]["antal"];
                if (j === 0) {
                    data1.push(100 / sum * ddata[i][j]["antal"]);
                }
                if (j === 1) {
                    data2.push(100 / sum * ddata[i][j]["antal"]);
                }
                if (j === 2) {
                    data3.push(100 / sum * ddata[i][j]["antal"]);
                }
                if (j === 3) {
                    data4.push(100 / sum * ddata[i][j]["antal"]);
                }
            }
        }

        var labels = ["Virksomheden har endnu ikke haft kontrol", "Har ikke Elite Smiley", "Har Elite Smiley", "Ultralavrisiko-virksomhed som får kontrol efter behov"];

        let chart = {
            labels: this.state.componentdata.map(function (c) {
                return c["kaedenavn"];
            }),
            datasets: [
                {
                    label: labels[0],
                    backgroundColor: "#72b1ff",
                    data: data1,
                    key: "asd1"
                },
                {
                    label: labels[1],
                    backgroundColor: "#ffa872",
                    data: data2,
                    key: "asd2"
                },
                {
                    label: labels[2],
                    backgroundColor: "#ffef72",
                    data: data3,
                    key: "asd3"
                },
                {
                    label: labels[3],
                    backgroundColor: "#e072ff",
                    data: data4,
                    key: "asdd"
                }
            ]

        };

        let chartoptions = {
            scales: {
                xAxes: {
                    stacked: true,
                    min: 0,
                    max: 100,
                    ticks: {
                        min: 0,
                        max: 100,
                        stepSize: 10,
                        callback: function (value, index, values) {
                            return value + "%";
                        },
                        precision: 0,
                        fixedStepSize: 1
                    }
                },
                yAxes: {
                    stacked: true,
                }
            },
            tooltips: {
                callbacks: {
                    label: function (k, j) { return ""; }
                }
            },
            legend: {
                onClick: (e) => e.stopPropagation()
            },
            indexAxis: 'y',
            plugins: {
                title: {
                    display: true,
                    text: (ctx) => 'Tooltip position mode: ' + ctx.chart.options.plugins.tooltip.position,
                },
            }
        };

        return <div>
            <div className="h3 text-center">
                Fordeling af elite smileys opgjort efter forretningskæde
            </div>
            <div>
                <Chart type='bar' data={chart} options={chartoptions} height={500} />
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
        </div>;*/
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Virksomhed/kaedefordeling'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}

export default KaedefordelingComponent;