import React from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { getUrl } from '../utils/utils.js'

class BranchefordelingComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [] };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        let chart = {
            labels: this.state.componentdata.map(function (c) {
                return c["branche"];
            }),
            datasets: [
                {
                    label: "Branche Type - Antal Virksomheder (logaritmisk skala)",
                    /*backgroundColor: ["#6cceff", "#ff9d6c"],*/
                    backgroundColor: "#6cceff",
                    data: this.state.componentdata.map(function (c) {
                        return c["antal"];
                    })
                }
            ],
        };

        let chartoptions = {
            scales: {
                yAxes: {
                    type: 'logarithmic',
                    ticks: {
                        min: 0,
                        max: 100000,
                        callback: function (value, index, values) {
                            if (value === 100000) return "100K";
                            if (value === 10000) return "10K";
                            if (value === 1000) return "1K";
                            if (value === 100) return "100";
                            if (value === 10) return "10";
                            if (value === 0) return "0";
                            return null;
                        }
                    },
                    title: {
                        display: true,
                        text: 'Antal virksomheder'
                    }
                },
                xAxes: {
                    title: {
                        display: true,
                        text: 'Branche'
                    }
                }
            },
            tooltips: {
                enabled: true
            },
            responsive: true,
            maintainAspectRatio: false,
        }

        let plugins = {
            plugins: {
                legend: {
                    display: true,
                    
                }
            }
        }

        return <div>
            <div className="h3 text-center">
                Branchefordeling af virksomheder
            </div>
            <div style={{ height: '50vh' }}>
                <Chart type='bar' data={chart} options={chartoptions} plugins={plugins}/>
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
        </div>;
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Virksomhed/branchefordeling'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}

export default BranchefordelingComponent;