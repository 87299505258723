import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import EliteSmileyComponent from './components/EliteSmiley';
import PixiBrancheComponent from './components/PixiBranche';
import KaedefordelingComponent from './components/Kaedefordeling'
import ReklamebeskyttelseComponent from './components/Reklamebeskyttelse'
import PostnummerfordelingComponent from './components/Postnummerfordeling'
import BranchefordelingComponent from './components/Branchefordeling'
import VirksomhedstypeComponent from './components/Virksomhedstype'
import KontrolresultatergruppesumComponent from './components/Kontrolresultatergruppesum'
import KontrollerPrUgeComponent from './components/KontrollerPrUge'
import KortAlleTilmeldteVirksomheder from './components/KortAlleTilmeldteVirksomheder'
import KortKontrollerDeSidsteXDage from './components/KortKontrollerDeSidsteXDage'

import './custom.css'
import KortKontrollerMedAnmaerkninger from './components/KortKontrollerMedAnmaerkninger';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                    <Routes>
                        <Route exact path='/' element={<Home/>} />
                        <Route path='/antal-kontroller-pr-uge' element={<KontrollerPrUgeComponent/>} />
                        <Route path='/fordeling-af-kontrolresultater' element={<KontrolresultatergruppesumComponent/>} />
                        <Route path='/fordeling-af-virksomhedstyper' element={<VirksomhedstypeComponent/>} />
                        <Route path='/fordeling-af-reklamebeskyttelse' element={<ReklamebeskyttelseComponent/>} />
                        <Route path='/fordeling-af-branchetyper' element={<BranchefordelingComponent/>} />
                        <Route path='/antal-virksomheder-pr-postnummer' element={<PostnummerfordelingComponent/>} />
                        <Route path='/fordeling-af-elite-smileys' element={<EliteSmileyComponent/>} />
                        <Route path='/fordeling-af-elite-smileys-for-butikskaeder' element={<KaedefordelingComponent/>} />
                        <Route path='/fordeling-af-pixi-brancher' element={<PixiBrancheComponent/>} />
                        <Route path='/kort-alle-tilmeldte-virksomheder' element={<KortAlleTilmeldteVirksomheder/>} />
                        <Route path='/kort-kontroller-de-sidste-dage' element={<KortKontrollerDeSidsteXDage/>} />
                        <Route path='/kort-kontroller-med-anmaerkninger' element={<KortKontrollerMedAnmaerkninger/>} />
                    </Routes>
            </Layout>
        );
    }
}
