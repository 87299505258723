import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {getUrl} from '../utils/utils.js'

class KontrolresultatergruppesumComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [] };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        let chart = {
            labels: this.state.componentdata.map(function (c) {
                return c["beskrivelse"] + " (antal: " + c["antal"]+")";
            }),
            datasets: [
                {
                    label: "Kontrol Resultater Sum",
                    backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],
                    data: this.state.componentdata.map(function (c) {
                        return c["antal"];
                    })
                }
            ]
        };

        let chartoptions = {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: 'Fordeling af kontrolresultater'
            }
        }

        return <div>
            <div className="h3 text-center">
                Fordeling af kontrolresultater
            </div>
            <div style={{height:'40vh'}}>
                <Doughnut data={chart} options={chartoptions} />
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
        </div>;
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Kontrol/kontrolresultatergruppesum'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}

export default KontrolresultatergruppesumComponent;