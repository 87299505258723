import React from 'react';
import { Chart } from 'react-chartjs-2';
import { getUrl } from '../utils/utils.js'

class PixiBrancheComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [] };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        let chart = {
            labels: this.state.componentdata.map(function (c) {
                return c["pixiBranche"];
            }),
            datasets: [
                {
                    label: "PIXI Branche Fordeling (logoritmisk skala)",
                    backgroundColor: "#6cceff",
                    data: this.state.componentdata.map(function (c) {
                        return c["antal"];
                    })
                }
            ],
            options: {
                indexAxis: 'y'
            }
        };

        let chartoptions = {
            scales: {
                yAxes: {
                    type: 'logarithmic',
                    ticks: {
                        min: 0,
                        max: 1000000,
                        callback: function (value, index, values) {
                            if (value === 1000000) return "1M";
                            if (value === 100000) return "100K";
                            if (value === 10000) return "10K";
                            if (value === 1000) return "1K";
                            if (value === 100) return "100";
                            if (value === 10) return "10";
                            if (value === 0) return "0";
                            return null;
                        }
                    },
                    title: {
                        display: true,
                        text: 'Antal virksomheder'
                    }
                },
                xAxes: {
                    title: {
                        display: true,
                        text: 'PIXI branche'
                    }
                }
            }
        }

        return <div>
            <div className="h3 text-center">
                PIXI branche fordeling
            </div>
            <div>
                <Chart type='bar' data={chart} options={chartoptions} />
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
        </div>;
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Virksomhed/pixibranchefordeling'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}

export default PixiBrancheComponent;