import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import KontrollerPrHelligdagComponent from './KontrollerPrHelligdag';
import KontrollerPrUgeDag from './KontrollerPrUgedag';



export class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div className="h4 text-center">Statistik og visualiseringer af data fra den <a href="https://www.findsmiley.dk/Sider/Forside.aspx">officielle danske smiley ordning</a></div>
                <div className="row mt-4">
                    <div className="col-md-6 p-3" style={{ backgroundColor: '#ffffff' }}>
                        <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                            <div className="h4">Grafer</div>

                            Kontroller
                            <ul>
                                <li><a href="/antal-kontroller-pr-uge">Antal kontroller pr. uge</a></li>
                                <li><a href="/fordeling-af-kontrolresultater">Fordeling af kontrolresultater</a></li>
                            </ul>
                            Virksomheder
                            <ul>
                                <li><a href="/fordeling-af-virksomhedstyper">Fordeling af virksomhedstyper</a></li>
                                <li><a href="/fordeling-af-reklamebeskyttelse">Fordeling af reklamebeskyttelse</a></li>
                                <li><a href="/fordeling-af-branchetyper">Branchetype fordeling</a></li>
                                <li><a href="/antal-virksomheder-pr-postnummer">Antal virksomheder pr. postnummer</a></li>
                                <li><a href="/fordeling-af-pixi-brancher">Pixi branche fordeling</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 p-3" style={{ backgroundColor: '#ffffff' }}>
                        <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                            <div className="h4">Kort</div>

                            Kontroller
                            <ul>
                                <li><a href="/kort-kontroller-de-sidste-dage">Kontroller de sidste 14 dage</a></li>
                                <li><a href="/kort-kontroller-med-anmaerkninger">Kontroller med anm&aelig;rkninger de sidste 30 dage</a></li>
                            </ul>
                            Virksomheder
                            <ul>
                                <li><a href="/kort-alle-tilmeldte-virksomheder">Alle tilmeldte virksomheder</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center align-top">


                </div>


                <div className="row mt-4">
                    <div className="col-md-6 p-3">
                        <KontrollerPrHelligdagComponent />
                    </div>
                    <div className="col-md-6 p-3">
                        <KontrollerPrUgeDag />
                    </div>
                </div>
            </div>
        );
    }
}
