import React from 'react';
import { Bar } from 'react-chartjs-2';
import {getUrl} from '../utils/utils.js'

class KontrollerPrUgeComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [] };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        let chart = {
            labels: this.state.componentdata.map(function (c) {
                return c["ugeNr"];
            }),
            datasets: [
                {
                    label: "Antal kontroller pr. uge henover året",
                    backgroundColor: "#6cceff",
                    data: this.state.componentdata.map(function (c) {
                        return c["antal"];
                    })
                }
            ]
        };

        let chartoptions = {
            scales: {
                x: {
                    scaleLabel: {
                        display: true,
                        labelString: 'Ugenummer'
                    },
                    title: {
                        display: true,
                        text: 'Ugenummer'
                    }
                },
                y: {
                    scaleLabel: {
                        display: true,
                        labelString: 'Antal kontroller'
                    },
                    title: {
                        display: true,
                        text: 'Antal kontroller'
                    }
                }
            }
        }

        return <div>
            <div className="h3 text-center">
                Antal kontroller pr. uge henover året
            </div>
            <div>
                <Bar data={chart} options={chartoptions} />
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
        </div>;
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Kontrol/kontrollerpruge'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}

export default KontrollerPrUgeComponent;