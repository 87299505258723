import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {getUrl} from '../utils/utils.js'

class ReklamebeskyttelseComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [] };
    }

    componentDidMount() {
        this.populateData();
    }

    render() {
        let chart = {
            labels: this.state.componentdata.map(function (c) {
                if (c["reklameBeskyttet"] === "0") {
                    return "Ikke reklamebeskyttet (antal: " + c["antal"] + ")";
                } else if (c["reklameBeskyttet"] === "1") {
                    return "Reklamebeskyttet (antal: " + c["antal"] + ")";
                } else {
                    return "Ukendt (antal: " + c["antal"] + ")";
                }
            }),
            datasets: [
                {
                    label: "Reklamebeskyttelse Fordeling",
                    backgroundColor: ["#6cceff","#ff9d6c"],
                    data: this.state.componentdata.map(function (c) {
                        return c["antal"];
                    })
                }
            ]
        };

        let chartoptions = {
            responsive: true,
            maintainAspectRatio: false,
        }

        return <div>
            <div className="h3 text-center">
                Fordeling af reklamebeskyttelse for virksomheder
            </div>
            <div style={{height:'40vh'}}>
                <Doughnut data={chart} options={chartoptions} />
            </div>
            <div>
                Kilde: <a href="https://www.findsmiley.dk/Sider/Downloads.aspx">findsmiley.dk</a>
            </div>
        </div>;
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Virksomhed/reklamebeskyttelsefordeling'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}

export default ReklamebeskyttelseComponent;