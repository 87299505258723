import React, { Component } from 'react';
import {getUrl} from '../utils/utils.js'

export class NavFooter extends Component {

    constructor(props) {
        super(props);
        this.state = { componentdata: [] };
    }

    componentDidMount() {
        this.populateData();
    }

    getDateString() {
        var senesteOpdatering = new Date(this.state.componentdata.map(function (c) {
            return c["senesteOpdatering"];
        }));

        if (isNaN(senesteOpdatering.getDate())) {
            return "";
        } else {
            return " Data er opdateret " + senesteOpdatering.getDate() + "/" + (senesteOpdatering.getMonth() + 1) + "-" + senesteOpdatering.getFullYear() + " kl. " + senesteOpdatering.getHours() + ":" + (senesteOpdatering.getMinutes() < 10 ? '0' : '') + senesteOpdatering.getMinutes() + ".";
        }
    }

    render() {
        return (
            <footer className="page-footer pt-4 text-dark mt-3" style={{ backgroundColor: '#e8e8e8' }}>
                <div className="container text-center text-md-left">
                    <div className="row">
                        <div className="col-md-6 mt-md-0 mt-3">
                            <h5 className="text-uppercase">Smiley Data</h5>
                            <p>Smiley Data er et website som formidler statistik og visualiseringer af de <a href="https://www.findsmiley.dk/Sider/Forside.aspx">officielle danske smiley data</a>.<br/><br/>{this.getDateString()}</p>
                        </div>
                        <hr className="clearfix w-100 d-md-none pb-3" />
                        <div className="col-md-6 mb-md-0 mb-3">
                            <h5 className="text-uppercase">Kontakt</h5>
                            <p>Anders Bruun Nørring<br /><a href="mailto:anders@ttcp.dk">anders@ttcp.dk</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

    async populateData() {
        const response = await fetch(getUrl('/api/Info/senesteopdatering/'));
        const apidata = await response.json();
        this.setState({ componentdata: apidata });
    }
}
