import React, { Component } from 'react';
import { Nav, Container, Navbar, NavDropdown } from 'react-bootstrap';
import './NavMenu.css';
import Smiley from './img/g10.png';

export class NavMenu extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbarGrafer = this.toggleNavbarGrafer.bind(this);
        this.setDropdownOpenGrafer = this.setDropdownOpenGrafer.bind(this);
        this.toggleNavbarKort = this.toggleNavbarKort.bind(this);
        this.setDropdownOpenKort = this.setDropdownOpenKort.bind(this);

        this.state = {
            collapsedGrafer: true,
            collapsedKort: true,
            dropdownOpenGrafer: false,
            dropdownOpenKort: false
        };
    }

    toggleNavbarGrafer() {
        this.setState({
            collapsedGrafer: !this.state.collapsedGrafer
        });
    }

    setDropdownOpenGrafer() {
        this.setState({
            dropdownOpenGrafer: !this.state.dropdownOpenGrafer
        });
    }

    toggleNavbarKort() {
        this.setState({
            collapsedKort: !this.state.collapsedKort
        });
    }

    setDropdownOpenKort() {
        this.setState({
            dropdownOpenKort: !this.state.dropdownOpenKort
        });
    }

    

    render() { 
        return (
            <header>
                <Navbar className="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark" style={{ backgroundColor:'#495464', marginBottom: '20px'}}>
                    <Container>
                        <Navbar.Brand href="/" className=''>
                            <img src={Smiley} height={30} width={30} style={{marginRight: 8}} alt=""/>
                            Smiley Data                                    
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="justify-content-end" style={{ width: "100%" }}>
                                <Nav.Link className="text-light" href="/">Forside</Nav.Link>
                                <NavDropdown title="Grafer" id="basic-nav-dropdown" align="end">
                                    <NavDropdown.Header>Kontroller</NavDropdown.Header>
                                    <NavDropdown.Item href="/antal-kontroller-pr-uge">Antal kontroller pr. uge</NavDropdown.Item>
                                    <NavDropdown.Item href="/fordeling-af-kontrolresultater">Fordeling af kontrolresultater</NavDropdown.Item>
                                    <NavDropdown.Header>Virksomheder</NavDropdown.Header>
                                    <NavDropdown.Item href="/fordeling-af-virksomhedstyper">Fordeling af virksomhedstyper</NavDropdown.Item>
                                    <NavDropdown.Item href="/fordeling-af-reklamebeskyttelse">Fordeling af reklamebeskyttelse</NavDropdown.Item>
                                    <NavDropdown.Item href="/fordeling-af-branchetyper">Branchetype fordeling</NavDropdown.Item>
                                    <NavDropdown.Item href="/antal-virksomheder-pr-postnummer">Antal virksomheder pr. postnummer</NavDropdown.Item>
                                    <NavDropdown.Item href="/fordeling-af-pixi-brancher">Pixi branche fordeling</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Kort" id="basic-nav-dropdown" align="end">
                                        <NavDropdown.Header>Kontroller</NavDropdown.Header>
                                        <NavDropdown.Item href="/kort-kontroller-de-sidste-dage">Kontroller de sidste 14 dage</NavDropdown.Item>
                                        <NavDropdown.Item href="/kort-kontroller-med-anmaerkninger">Kontroller med anm&aelig;rkninger de sidste 30 dage</NavDropdown.Item>
                                        <NavDropdown.Header>Virksomheder</NavDropdown.Header>
                                        <NavDropdown.Item href="/kort-alle-tilmeldte-virksomheder">Alle tilmeldte virksomheder</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
